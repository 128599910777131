<template>
  <div class="home">
    <Header></Header>
    <div class="skills-main-box">
      <div class="skills-banner-box">
        <div class="banner-img">
          <img src="../assets/img/skills-banner.jpg" alt="" />
        </div>
        <div class="big-tit">ZUI 使用技巧</div>
        <div class="select-box">
          <div class="select-box-rel">
            <div class="left-img" @click="selectFn">
              <img src="../assets/img/skills-search.png" alt="" />
            </div>
            <div class="right-input">
              <input
                type="text"
                v-model="searchText"
                placeholder="搜索"
                class="input"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="skills-content">
        <div class="content-select-input">搜索到 {{ totalCount }} 条结果</div>
        <div class="content-box" v-if='allMsg.length>0'>
          <div
            class="content-slide"
            v-for="item in allMsg"
            :key="item.id"
            @click="goSkillsMsg(item.id)"
          >
            <div class="top-box-video">
              <div class="box-text">
                <img
                  v-if="item.positionList[0]"
                  :src="item.positionList[0].assetList[0].asset.url"
                />
              </div>
              <div class="block-bg">
                <div class="block-bg-rel">
                  <div class="video-logo">
                    <img src="../assets/img/skills-video-logo.png" alt="" />
                  </div>
                  <!-- <div class="time-p">00:46</div> -->
                </div>
              </div>
            </div>
            <div class="bottom-text-main">
              <div class="top-logo-text">
                <p
                  class="name-logo"
                  v-if="item.extContentInfo.CUSTOMER_PORTAL_VERSION"
                >
                  {{ item.extContentInfo.CUSTOMER_PORTAL_VERSION[0].title }}
                </p>
                <p class="time">{{ timestampToTime(item.publishTime) }}</p>
              </div>
              <p class="big-p" v-if="item.positionList[1].assetList[0]">
                {{ item.positionList[1].assetList[0].asset.baseText }}
              </p>
              <p class="two-p" v-if="item.positionList[1].assetList[0]">
                {{ item.positionList[2].assetList[0].asset.baseText }}
              </p>
              <p class="big-p">支持机型</p>
              <div class="about-slide">
                <div
                  class="slide"
                  v-for="item2 in item.extContentInfo
                    .CUSTOMER_PORTAL_PRODUCT_NUMBER"
                  :key="item2.value"
                 @click.stop="selectFn2(item2.title)"
                >
                  {{ item2.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="see-more"
          v-if="allMsgNum > 1 && this.pageNum != allMsgNum"
          @click="getMoreMsg"
        >
          查看更多
        </div>
        <div class="product05-content product05-content2" v-if="allMsg.length<1&&!testModelStatus">
          	<img class="no-msg" src="../assets/img/loading.png" alt="">
        </div>
        <div class="product05-content product05-content2" v-else-if="allMsg.length<1&&testModelStatus">
          	<img class="no-msg2" src="../assets/img/zanwushuju.png" alt="">
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
//设置html font-size
document.documentElement.style.fontSize =
  document.documentElement.clientWidth / 7.5 + "px";
window.onresize = () => {
  document.documentElement.style.fontSize =
    document.documentElement.clientWidth / 7.5 + "px";
};
/* 
var blockIdArr={
    335 最新消息Banner
    327 最新消息>内容详情
    362 更新信息>机型图
    334 更新记录>内容详情
    333 内测机型Banner
    366 内侧机型>机型列表
    363 二维码-微信
    364 二维码-微博
    indexBanner:335,
    newMsg:327,
    newsjixing:362,
    update:334,
    neicebanner:333,
    neicejixing:366,
    vxcode:363,
    wbcode:364
}
*/
import instance from "../utils/http";
import interfaces from "../utils/baseUrl";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      //所有新闻
      allMsg: [],
      //首页banner
      bannerdata: "",
      //最新消息列表
      newMsg: [],
      //更新信息banner
      updatedata: [{ coverPicture: "" }],
      rightMoreArr: [],
      bannerUrl: "",
      pageNum: 1,
      pageSize: 8,
      searchText: "小新 Pad 2022",
      leftNavList: [],
      leftNavListStatus: false,
      showIndex: 0,
      //左侧导航code
      leftCode: "",
      leftValue: "",
      navigators: [],
      allNavigators: [],
      allMsgNum: 0,
      totalCount: 0,
      testModelStatus:false,
    };
  },
  beforeMount: function () {
    //获取链接参数
    var query = this.$route.query;
    //设置整体展厅参数
    // // this.cacheKey = query.cacheKey;
    // this.getContent({"id": query.searchText})
    this.searchText = query.searchText;
    //console.log(this.searchText);
    var selectMsg = {
      token: "",
      param: {
        blockId: interfaces.blockIdArr.skillBlockID,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        keywords: this.searchText,
        filterConditionList: [],
      },
    };

    this.searchFn(selectMsg);
  },
  //初始化执行
  created: function () {
    let $this = this;
    // $this.getBaseInfo(335)
  },
  //事件
  methods: {
    searchFn(data,more) {
      instance
        .post(`/open-api/standard/v1/searchContent`, data, {
          headers: {
            "Content-Type": "application/json",
            "Token-type": "bearer",
          },
        })
        .then((res) => {
          //console.log(res);
          if (res&&res.data.result) {
            this.totalCount = res.data.result.totalCount;
            this.allMsgNum = res.data.result.totalPage;
            if(more){
                this.allMsg=this.allMsg.concat(res.data.result.records);
            }else{
                this.allMsg=res.data.result.records
            }
            this.$forceUpdate()
            this.testModelStatus=true
          }else{
            instance
              .post(`/open-api/standard/v1/searchContent`, data, {
                headers: {
                  "Content-Type": "application/json",
                  "Token-type": "bearer",
                },
              })
              .then((res) => {
                //console.log(res);
                if (res&&res.data.result) {
                  this.totalCount = res.data.result.totalCount;
                  this.allMsgNum = res.data.result.totalPage;

                  if(more){
                      this.allMsg=this.allMsg.concat(res.data.result.records);
                  }else{
                      this.allMsg=res.data.result.records
                  }
                  this.testModelStatus=true
                   this.$forceUpdate()
                }else{
                  
                }
                
              });
          }
          
        });
    },
    
    selectFn(more) {
      var selectMsg = {
        token: "",
        param: {
          blockId: interfaces.blockIdArr.skillBlockID,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          keywords: this.searchText,
          filterConditionList: [],
        },
      };
      if(more){
        this.searchFn(selectMsg,more);   
      }else{
        this.searchFn(selectMsg);
      }
    },
    goSkillsMsg(id) {
      //console.log(id);
      this.$router.push({
        name: "killsMessage",
        query: {
          id: id,
        },
      });
    },
    getMoreMsg() {
      this.pageNum = this.pageNum + 1;
      //console.log();
      this.selectFn("more");
    },
     timestampToTime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      return Y + M + D;
    },
    selectFn2(item) {
      console.log(item)
        //console.log(this.searchText)
      this.searchText=item;
      this.pageNum=1;
      this.allMsg=[];
       var selectMsg = {
        token: "",
        param: {
          blockId: interfaces.blockIdArr.skillBlockID,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          keywords: this.searchText,
          filterConditionList: [],
        },
      };
      this.searchFn(selectMsg);
    },
  },
};
</script>
<style lang="less" scoped> 
.product05-content{
	width: 7.2rem;
	margin: 0 auto;
	overflow: hidden;
}
.no-msg2{
  width: 2.2rem !important;
  margin: 0 auto;
  margin-top: .6rem;
  padding-bottom: .6rem;
}
.no-msg2 img{
  width: 100%;
}
.skills-main-box{
  width: 100%;
  min-height: calc(100vh - 3rem);
  overflow: hidden;
  background: #ffffff;
  position: relative;
  padding-top: .9rem;
  box-sizing: border-box;
}
.skills-main-box img{
	display: block;
	width: 100%;
}
.skills-main-box .skills-banner-box{
    position: relative;
    width: 100%;
    height:2.8rem;
    overflow: hidden;
}
.skills-main-box .skills-banner-box .top-tit-a{
    font-size:.34rem;
    color:#000000;
    overflow: hidden;
    display: flex;
    position: absolute;
    top:.6rem;
    left:7.2rem;
}
.skills-main-box .skills-banner-box .top-tit-a .p1{
    font-size:.24rem;
    color:#000000;
}
.skills-main-box .skills-banner-box .top-tit-a .shu{
    display: inline-block;
    padding: 0 .2rem;
    font-size:.24rem;
    color:#000000;
}
.skills-main-box .skills-banner-box .big-tit{
    position: absolute;
    top:.6rem;
    left: 0;
    width: 100%;
    text-align: center;
    font-size:.34rem;
    color: #000000;
    font-weight: 600;
}
.skills-main-box .skills-banner-box .select-box{
    position: absolute;
    top:1.52rem;
    left: 50%;
    transform: translate(-50%,0);
    width:5.7rem;
    height:.6rem;
    line-height: .6rem;
    background: #ffffff;
    color:#121212;
    font-size: .24rem;
    border: .02rem solid #a2a3a3;
    border-radius: .6rem;
    padding: 0 .3rem;
    box-sizing: border-box;
    background: #ffffff;
}
.skills-main-box .skills-banner-box .select-box .select-box-rel{
    position: relative;
    width: 100%;
    height: 100%;
}
.skills-main-box .skills-banner-box .select-box .select-box-rel .left-img{
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
    width:.34rem;
    height: .34rem;
    right:0;
}
.skills-main-box .skills-banner-box .select-box .select-box-rel .right-input{
    width: 80%;
    height: 100%;
    line-height: .6rem;
}
.skills-main-box .skills-banner-box .select-box .select-box-rel .right-input input{
    outline: none;
    width: 100%;
    font-size: .24rem;
    background: none;
}








.skills-main-box .skills-banner-box .slide-ul{
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 1.5rem;
    display: flex;
    justify-content: center;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box{
   width: auto;
   margin: 0 auto;
   overflow: hidden;
   height: 100%;
   position: relative;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .slide-li{
    float: left;
    width: 1.9rem;
    height: 100%;
    margin-left: 1rem;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .slide-li:nth-of-type(1){
    margin-left: 0;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .slide-li .top-img{
    width: 100%;
    height:.8rem;
    overflow: hidden;
    position: relative;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .slide-li .top-img img{
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,0);
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .slide-li .top-img .img1{
    display: block;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .slide-li .top-img .img2{
    display: none;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .slide-li .bottom-p{
    width: 100%;
    text-align: center;
    font-size:.3rem;
    color: #999999;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .move-line{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1.9rem;
    height:.1rem;
    background: #5f7bfd;
}
.no-msg{
  width: 180px !important;
  margin: 0 auto;
  margin-top: 75px;
  padding-bottom: 75px;
}
.no-msg img{
  width: 100%;
}

.skills-content{
    padding: 0.5rem 0.3rem;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    box-sizing: border-box;
}
.skills-content .content-select-input{
    font-size: .2rem;
    color:#999999 ;
}
.skills-content .content-select-input select{
    border: none;
    font-size: 15px;
}
.skills-content .content-box{
    width: 100%;
    overflow: hidden;
}
.skills-content .content-box .content-slide{
    width: 100%;
    border-radius: 10px;
    background: #f5f5f6;
    cursor: pointer;
    overflow: hidden;
    padding-bottom:10px;
    margin-top:.25rem;
}
.skills-content .content-box .content-slide .top-box-video{
    position: relative;
    width: 100%;
    background:#ebebed;
}
.skills-content .content-box .content-slide .top-box-video .box-text{
    text-align: center;
    overflow: hidden;
}
.skills-content .content-box .content-slide .top-box-video .box-text .p1{
    font-size:.48rem;
    color:#7a6afb;
}
.skills-content .content-box .content-slide .top-box-video .box-text .p2{
    font-size:.48rem;
    color:#000000;
}
.skills-content .content-box .content-slide .top-box-video .block-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    // display: none;
}
// .skills-content .content-box .content-slide:hover .top-box-video .block-bg{
//     display: block;
// }
.skills-content .content-box .content-slide .top-box-video .block-bg .block-bg-rel{
    position: relative;
    width: 100%;
    height: 100%;
}
.skills-content .content-box .content-slide .top-box-video .block-bg .block-bg-rel .video-logo{
    position: absolute;
    bottom:.12rem;
    left:.3rem;
    width: .8rem;
    height: .8rem;
}
.skills-content .content-box .content-slide .top-box-video .block-bg .block-bg-rel .time-p{
    position: absolute;
    top:4rem;
    left:1.25rem;
    color: #ffffff;
    font-size:.3rem;
}
.skills-content .content-box .content-slide .bottom-text-main{
    width: 100%;
    padding:.3rem .3rem;
    overflow: hidden;
    box-sizing: border-box;
}
.skills-content .content-box .content-slide .bottom-text-main .top-logo-text{
    width: 100%;
    overflow: hidden;
}
.skills-content .content-box .content-slide .bottom-text-main .top-logo-text .name-logo{
    float: left;
    padding:.09rem .18rem;
    border-radius: .09rem;
    background:#5f7bfd;
    font-size:.24rem;
    color: #ffffff;
}
.skills-content .content-box .content-slide .bottom-text-main .top-logo-text .time{
    float: left;
    margin-left:.1rem;
    line-height:.52rem;
    font-size:.24rem; 
    color:#999999;
}
.skills-content .content-box .content-slide .bottom-text-main .big-p{
    margin-top:.3rem;
    font-size:.32rem;
    font-weight: 600;
    color: #000000;
}
.skills-content .content-box .content-slide .bottom-text-main .two-p{
    margin-top:.2rem;
    color:#666666;
    font-size: .28rem;
}
.skills-content .content-box .content-slide .bottom-text-main .about-slide{
    overflow: hidden;
    margin-top:.2rem;
}
.skills-content .content-box .content-slide .bottom-text-main .about-slide .slide{
    float: left;
    padding:.12rem .15rem;
    margin-right:.14rem;
    background: #ffffff;
    font-size:.2rem;
    color:#666666;
    margin-top: .2rem;
}
.skills-content .content-box .content-slide .bottom-text-main .about-slide .slide:nth-of-type(2n+1){
    margin-left: 0;
}
.skills-content .see-more{
    margin: 0 auto;
    margin-top:.8rem;
    width:1.55rem;
    height: .7rem;
    line-height:.7rem;
    text-align: center;
    font-size:.24rem;
    color:#000000;
    border: 1px solid #000000;
    border-radius: 4px;
    cursor: pointer;
}
</style>